import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import Header from "../components/General-components/Header";
import AdsBox from "../components/General-components/AdsBox";
import Footer from "../components/General-components/Footer";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

const useStyles = makeStyles((theme) => ({
  mainBody: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px 25px",
    },
  },

  body: {
    padding: "40px 120px",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 25px",
    },
  },

  title: {
    fontSize: 36,
    color: "#fff",
    fontWeight: "500",
    margin: "0px auto",
    textAlign: "center",
    width: window.innerWidth * 0.453,
    [theme.breakpoints.down("xs")]: {
      width: 325,
      fontSize: 20,
      margin: "auto",
      textAlign: "left",
    },
  },

  second_text: {
    fontSize: 18,
    lineHeight: 1.4,
    fontWeight: 400,
    marginLeft: "auto",
    textAlign: "center",
    marginRight: "auto",
    background: "transparent",
    width: window.innerWidth * 0.4,
    color: theme.palette.secondary.main,
    marginTop: 12,
    [theme.breakpoints.down("xs")]: {
      width: 300,
      fontSize: 14,
    },
  },

  flex: { gap: 48, display: "flex" },

  formWrapper: {
    gap: 32,
    width: "100%",
    display: "flex",
    maxWidth: "800px",
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "column",
    padding: "80px 0 120px 0",
  },

  inputWrapper: {
    gap: 4,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  input: {
    padding: 16,
    fontSize: 16,
    color: "#fff",
    width: "100%",
    borderRadius: 2,
    boxSizing: "border-box",
    border: "none !important",
    outline: "none !important",
    backgroundColor: "#1A1A1A",
  },
}));

const Dele = ({ isOpen, onClose, state, setState, clear }) => {
  const classes = useStyles();
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);

  const disabled = !state.password.length;

  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.post("https://api.myqpay.app/api/account", {
        email: state.email,
        password: state.password,
      });

      clear();
      onClose();
      setLoading(false);

      toast({
        duration: 9000,
        isClosable: true,
        title: "Success",
        status: "success",
        description: "Your account has been deleted",
      });
    } catch (err) {
      setLoading(false);

      toast({
        duration: 9000,
        isClosable: true,
        title: "Error",
        status: "error",
        description: "An error occurred",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalCloseButton />
        <ModalBody style={{ padding: 16, textAlign: "center !important" }}>
          <p className={classes.title} style={{ fontSize: 20 }}>
            Delete Account
          </p>

          <p
            className={classes.second_text}
            style={{ fontSize: 14, marginTop: 8, marginBottom: 12 }}
          >
            This feature allows you to permanently delete your Qpay account,
            along with all personal data stored within the platform.
          </p>

          <input
            type="password"
            value={state.password}
            onChange={(e) =>
              setState((prev) => ({ ...prev, password: e.target.value }))
            }
            className={classes.input}
            placeholder="Enter password"
          />
        </ModalBody>

        <ModalFooter style={{ width: "100%" }}>
          <button
            onClick={handleDelete}
            disabled={disabled || loading}
            style={{
              border: "none",
              width: "100%",
              borderRadius: 4,
              padding: "16px",
              fontSize: "15px",
              color: "#EE3131",
              cursor: "pointer",
              backgroundColor: "#fff",
              outline: "none !importnat",
            }}
          >
            {loading ? "Loading..." : "Delete Account"}
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const init = {
  checked: false,
  firstName: "",
  lastName: "",
  email: "",
  tag: "",
  info: "",
  password: "",
};

const DeleteAccount = () => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearForm = () => {
    setState(init);
  };

  const [state, setState] = React.useState(init);

  const isDisabled =
    !state.checked ||
    !state.firstName.trim() ||
    !state.lastName.trim() ||
    !state.email.trim() ||
    !state.tag.trim();

  return (
    <div>
      <div className={classes.mainBody}>
        <Header />
      </div>

      <div className={classes.body}>
        <p className={classes.title}>Delete Account</p>

        <p className={classes.second_text}>
          This feature allows you to permanently delete your Qpay account, along
          with all personal data stored within the platform.
        </p>

        <div className={classes.formWrapper}>
          <div className={classes.flex}>
            <div className={classes.inputWrapper}>
              <label>First name</label>
              <input
                value={state.firstName}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, firstName: e.target.value }))
                }
                placeholder="Enter first name"
                className={classes.input}
              />
            </div>

            <div className={classes.inputWrapper}>
              <label>Last name</label>
              <input
                value={state.lastName}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, lastName: e.target.value }))
                }
                placeholder="Enter last name"
                className={classes.input}
              />
            </div>
          </div>

          <div className={classes.flex}>
            <div className={classes.inputWrapper}>
              <label>Email address</label>
              <input
                value={state.email}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, email: e.target.value }))
                }
                placeholder="Enter email address"
                className={classes.input}
              />
            </div>

            <div className={classes.inputWrapper}>
              <label>Qpay tag</label>
              <input
                value={state.tag}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, tag: e.target.value }))
                }
                placeholder="Enter Qpay tag/ID"
                className={classes.input}
              />
            </div>
          </div>
          <div>
            <div className={classes.inputWrapper}>
              <label>Additional Information</label>
              <textarea
                value={state.info}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, info: e.target.value }))
                }
                rows={12}
                className={classes.input}
                placeholder="We’re sorry to see you go! Please share your reasons for account deletion"
              />
            </div>
          </div>

          <div
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              gap: 16,
            }}
          >
            <input
              id="check"
              type="checkbox"
              checked={state.checked}
              onChange={(e) => {
                setState((prev) => ({ ...prev, checked: e.target.checked }));
              }}
            />
            <label htmlFor="check">
              I accept that my Qpay account will be permanently deleted.
            </label>
          </div>

          <button
            onClick={onOpen}
            disabled={isDisabled}
            style={{
              border: "none",
              borderRadius: 4,
              padding: "16px",
              fontSize: "15px",
              color: "#EE3131",
              cursor: "pointer",
              backgroundColor: "#fff",
              outline: "none !importnat",
            }}
          >
            Delete Account
          </button>
        </div>

        <AdsBox />
        <Footer />
      </div>

      <Dele
        state={state}
        isOpen={isOpen}
        onClose={onClose}
        setState={setState}
        clear={clearForm}
      />
    </div>
  );
};

export default DeleteAccount;
