import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { HiChevronDown } from "react-icons/hi";

const useStyles = makeStyles((theme) => ({
  border_bottom: {
    borderTop: "2px solid #1A1A1A",
    padding: "30px 120px",
    maxWidth: 1200,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 25px",
    },
  },
  inner_flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  white_text: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.white,
    marginLeft: 8,
    // [theme.breakpoints.down("xs")]: {
    //   width: 196,
    // },
  },
  product_text: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      width: 375,
    },
  },
  down_arrow: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  menu_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menu_item: {
    fontSize: 18,
    fontWeight: "500",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menu_item_sub: {
    fontSize: 18,
    fontWeight: "500",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    marginLeft: 30,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  modal_main: {
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  modal_body: {
    display: "flex",
    justifyContent: "center",
    outline: "none",
    alignItems: "center",
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: 258,
      margin: "auto",
    },
  },
  inner_modal: {
    color: "#fff",
    marginTop: 100,
    background: "transparent",
    background: "#090909",
    padding: "60px 40px",
  },
  modal_list: {
    display: "flex",
    alignItems: "center",
    marginTop: 40,
    background: "transparent",
    cursor: "pointer",
  },
  modal_icon: {
    width: 45,
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      width: 24,
    },
  },
  modal_title: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "500",
    background: "transparent",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  modal_text: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    background: "transparent",
    marginTop: 4,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  text_div: {
    marginLeft: 20,
    background: "transparent",
  },
}));

const DynamicNav = () => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const pathname = window.location.pathname;

  const navigate = useNavigate();
  const route = (route) => {
    navigate(`/${route}`);
  };

  return (
    <div className={classes.border_bottom}>
      <div className={classes.inner_flex}>
        <p className={classes.product_text}>
          Products /{" "}
          <span
            className={classes.white_text}
            onClick={() => setModalOpen(true)}
          >
            {pathname === "/money-transfer"
              ? "Money Transfer"
              : pathname === "/currency-exchange"
              ? "Currency Exchange"
              : pathname === "/virtual-accounts"
              ? "Virtual Accounts"
              : "A path"}
            <HiChevronDown className={classes.down_arrow} />
          </span>
        </p>

        {pathname === "/money-transfer" ? (
          <div className={classes.menu_box}>
            <p className={classes.menu_item}>QPay Express</p>
            <p className={classes.menu_item_sub}>Foreign Virtual Accounts</p>
            <p className={classes.menu_item_sub}>Usernames</p>
          </div>
        ) : (
          <>
            {pathname === "/currency-exchange" ? (
              <>
                <div className={classes.menu_box}>
                  <p className={classes.menu_item}>P2P Exchange</p>
                  <p className={classes.menu_item_sub}>QPay Express</p>
                </div>
              </>
            ) : (
              <>
                {pathname === "/virtual-accounts" ? (
                  <>
                    <div className={classes.menu_box}>
                      <p className={classes.menu_item}>Virtual Account</p>
                      <p className={classes.menu_item_sub}>Virtual Card</p>
                      <p className={classes.menu_item_sub}>
                        Multicurrency wallets
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </div>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className={classes.modal_main}
      >
        <div className={classes.modal_body} onClick={() => setModalOpen(false)}>
          <div className={classes.inner_modal}>
            <div
              className={classes.modal_list}
              style={{ marginTop: 0 }}
              onClick={() => route("money-transfer")}
            >
              <img src="money.png" className={classes.modal_icon} />
              <div className={classes.text_div}>
                <p className={classes.modal_title}>Money Transfer</p>
                <p className={classes.modal_text}>
                  Get paid by foreign clients with no hassle...
                </p>
              </div>
            </div>
            <div
              className={classes.modal_list}
              onClick={() => route("currency-exchange")}
            >
              <img src="currency.png" className={classes.modal_icon} />
              <div className={classes.text_div}>
                <p className={classes.modal_title}>Currency Exchange</p>
                <p className={classes.modal_text}>
                  Better rates for every currency exchange...
                </p>
              </div>
            </div>
            <div
              className={classes.modal_list}
              onClick={() => route("virtual-accounts")}
            >
              <img src="money.png" className={classes.modal_icon} />
              <div className={classes.text_div}>
                <p className={classes.modal_title}>Multicurrency wallet</p>
                <p className={classes.modal_text}>
                  Welcome to a word of limitless financial...
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DynamicNav;
