import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import MoneyTransfer from "./pages/MoneyTransfer";
import CurrencyExchange from "./pages/CurrencyExchange";
import VirtualAccounts from "./pages/VirtualAccounts";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccount from "./pages/DeleteAccount";

const AppRoutes = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/money-transfer" element={<MoneyTransfer />} />
      <Route path="/currency-exchange" element={<CurrencyExchange />} />
      <Route path="/virtual-accounts" element={<VirtualAccounts />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/delete-account" element={<DeleteAccount />} />
    </Routes>
  );
};

export default AppRoutes;
